<template>
    <b-container fluid>
        <!-- User Interface controls -->
        <b-row>
            <b-col lg="6" offset-lg="2"><h1 class="title">Sedes</h1></b-col>
            <b-col lg="2" class="my-1 text-right">
                <b-btn-group>
                    <b-button size="sm" @click="onFilter()" variant="primary">
                        <font-awesome-icon icon="search"/> Filtro
                    </b-button>
                    <b-button size="sm" @click="onCreate()" variant="danger">
                        <font-awesome-icon icon="plus"/> Adicionar
                    </b-button>
                </b-btn-group>
            </b-col>
        </b-row>
        <div v-show="showFilter">
            <b-row>
                <b-col lg="8" offset-lg="2">
                   <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                label="Filtrar"
                                label-for="filterInput"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        v-model="filter"
                                        type="search"
                                        id="filterInput"
                                        placeholder="Buscar"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">
                                            Limpiar
                                        </b-button>
                                        <b-button @click="onFilterNames" :variant="showFilterNames ? 'danger' : 'info'">
                                            <font-awesome-icon :icon="showFilterNames ? 'eye-slash' : 'filter'"/>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col sm="8" md="8" lg="4">
                            <b-form-group
                                label="Ordernar:"
                                label-for="sortBySelect"
                            >
                                <b-input-group size="sm">
                                    <b-form-select
                                        v-model="sortBy"
                                        id="sortBySelect"
                                        :options="sortOptions"
                                        class="w-75"
                                    >
                                        <template v-slot:first>
                                            <option value="">-- ninguno --</option>
                                        </template>
                                    </b-form-select>
                                    <b-form-select
                                        v-model="sortDesc"
                                        size="sm"
                                        :disabled="!sortBy"
                                        class="w-25"
                                    >
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="4" lg="2">
                            <b-form-group
                                label="Cantidad :"
                                label-for="perPageSelect"
                            >
                                <b-form-select
                                    v-model="perPage"
                                    id="perPageSelect"
                                    size="sm"
                                    :options="pageOptions"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6" v-show="showFilterNames">
                            <b-form-group
                                label="Filtrar en:"
                                description="Deje todo sin marcar para filtrar todos los datos"
                            >
                                <b-form-checkbox-group 
                                    v-model="filterOn"
                                    :options="optionsFilterNames"
                                >
                                </b-form-checkbox-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <!-- Main table element -->
        <b-row>
            <b-col lg="8" offset-lg="2">
                <b-table
                    show-empty
                    small
                    stacked="md"
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filterIncludedFields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :tbody-transition-props="transProps"
                >
                    <template v-slot:cell(Logo)="row">
                        <b-img :src="row.item.Logo" center alt="Logo" height="25px" max-width="25px!important"></b-img>
                    </template> 
                    <template v-slot:cell(colorPrimary)="row">
                        <b-row>
                            <b-col sm="4" offset-sm="2">{{row.item.ColorPrimario }}</b-col>
                            <b-col sm="4" :style="{backgroundColor:row.item.ColorPrimario}"></b-col>
                        </b-row> 
                    </template> 
                    <template v-slot:cell(colorSecundary)="row">
                        <b-row>
                            <b-col sm="4" offset-sm="2">{{row.item.ColorSecundario }}</b-col>
                            <b-col sm="4" :style="{backgroundColor:row.item.ColorSecundario}"></b-col>
                        </b-row> 
                    </template> 
                    <template v-slot:cell(traceability)="row">
                        <b-button size="sm" v-b-tooltip.hover.top="row.item.Registro" variant="light">
                            <font-awesome-icon icon="user" />
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover.top="row.item.Actualizado" variant="light" v-if="row.item.Registro!=row.item.Actualizado">
                            <font-awesome-icon icon="user" />
                        </b-button>
                    </template>   
                    <template v-slot:cell(actions)="row">
                        <b-button-group>
                            <b-button size="sm" @click="row.toggleDetails" :variant="row.detailsShowing ? 'danger' : 'info'">
                                <font-awesome-icon :icon="row.detailsShowingedit ? 'eye-slash' : 'eye'" />
                            </b-button>
                            <b-button size="sm" @click="onEdit(row.item, row.index)" variant="info">
                                <font-awesome-icon icon="edit" />
                            </b-button>
                            <b-button size="sm" @click="onDelete(row.item.internalId, row.index)" variant="danger">
                                <font-awesome-icon icon="trash" />
                            </b-button>
                        </b-button-group>
                    </template>

                    <template v-slot:row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">
                                    {{ key }}: {{ value }}
                                </li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
            <b-col sm="12" md="6" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal
            :id="dataModal.id"
            :title="dataModal.title"
            centered
            @hide="resetDataModal"
            :hide-footer="true"
        >
            <b-row class="justify-content-md-center">
                <b-col sm="12" md="12" lg="10" offset-lg="1">
                    <b-form-group label="Nombre Sede:">
                        <b-form-input v-model="dataModal.dataName.value" :state="nameState"></b-form-input>
                        <b-form-invalid-feedback v-html="dataModal.dataName.Feedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="10" offset-lg="1">
                    <b-form-group label="Logo Sede :">
                        <b-form-file :state="Boolean(dataModal.dataLogo.Status)" placeholder="Adjuntar Logo" v-on:change="handleFileUpload($event.target.files)" accept="image/*"></b-form-file>                        
                        <b-form-invalid-feedback v-html="dataModal.dataLogo.Feedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="5" offset-lg="1">
                    <b-form-group label="Color Primario Sede:">
                        <b-form-input v-model="dataModal.dataPrimaryColor.value" :state="primaryColorState" type="color"></b-form-input>
                        <b-form-invalid-feedback v-html="dataModal.dataPrimaryColor.Feedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="5">
                    <b-form-group label="Color Secundario Sede :">
                        <b-form-input v-model="dataModal.dataSecundaryColor.value" :state="secundaryColorState" type="color"></b-form-input>
                        <b-form-invalid-feedback v-html="dataModal.dataSecundaryColor.Feedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="text-right">
                <b-col sm="12" md="12" lg="12" class="my-3">
                    <b-button size="sm" variant="primary" v-if="btnCreate" :disabled="isDisabledCreate" @click="onStore()"><font-awesome-icon icon="plus"></font-awesome-icon> Adicionar</b-button>
                    <b-button size="sm" variant="primary" v-if="btnUpdate" :disabled="isDisabledUpdate" @click="onUpdate()"><i class="fa fa-check"></i> Actualizar</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'CampusComponent',
    data() {
        return {
            getData: '/admin/campuses',
            setDataStore: '/admin/campuses',
            setDataUpdate: '/admin/campuses/update',
            setDataDelete: '/admin/campuses/destroy',
            showFilter: false,
            showFilterNames : false,
            optionsFilterNames: [
                {
                    value:'Nombre',
                    text:'Nombre'
                }
            ],
            optionsAddress: [],
            optionsBusinessLines: [],
            transProps: {
                // Transition name
                name: "flip-list"
            },
            items: [],
            fields: [
                {
                    key:'Nombre',
                    label:'Nombre',
                    sortable:true,
                    sortDirection:'asc'
                },
                {
                    key:'Logo',
                    label:'Logo',
                    class:'text-center'
                },
                {
                    key:'colorPrimary',
                    label:'Color Primario',
                    class:'text-center'
                },
                {
                    key:'colorSecundary',
                    label:'Color Secundario',
                    class:'text-center'
                },
                {
                    key:'traceability',
                    label:'Traza',
                    class:'text-left',
                    thStyle:{
                        width: '10%!important'
                    }
                },
                {
                    key:'actions',
                    label:'Opciones',
                    class:'text-center',
                    thStyle:{
                        width: '10%!important'
                    }
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [15, 25, 50, 100],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            dataModal: {
                id: "dataSucursalModal_",
                title: "",
                indexId:null,
                dataId:null,
                dataName: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataLogo: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataPrimaryColor: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataSecundaryColor: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                url: null,
                btnCreate : false,
                btnUpdate : false
            },
            btnCreate : false,
            btnUpdate : false
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
        isDisabledCreate() {
            return !this.dataModal.btnCreate;
        },
        isDisabledUpdate() {
            return !this.dataModal.btnUpdate;
        },
        nameState() {
            return this.dataModal.dataName.Status;
        },
        logoState() {
            return this.dataModal.dataLogo.Status;
        },
        primaryColorState() {
            return this.dataModal.dataPrimaryColor.Status;
        },
        secundaryColorState() {
            return this.dataModal.dataSecundaryColor.Status;
        }
    },
    mounted() {
        this.$root.getConexion().get(this.getData).then(response => {
              let data = response.data;
              if(data.response) {
                this.items = data.data.records;
                this.totalRows = data.data.count;
              }
            }).catch(error => {
                this.errors(error.response);
            });
    },
    watch: {
        'dataModal.dataName.value': function (val) {
            this.dataModal.dataName.Feedback = null;
            if(val) {
                this.dataModal.dataName.Status = true; 
            } else {
                this.dataModal.dataName.Status = false;
            }
        },
        'dataModal.dataLogo.value': function (val) {
            this.dataModal.dataLogo.Feedback = null;
            if(val) {
                this.dataModal.dataLogo.Status = true;
            } else {
                this.dataModal.dataLogo.Status = false;
            }
        },
        'dataModal.dataPrimaryColor.value': function (val) {
            this.dataModal.dataPrimaryColor.Feedback = null;
            if(val) {
                this.dataModal.dataPrimaryColor.Status = true; 
            } else {
                this.dataModal.dataPrimaryColor.Status = false;
            }
        },
        'dataModal.dataSecundaryColor.value': function (val) {
            this.dataModal.dataSecundaryColor.Feedback = null;
            if(val) {
                this.dataModal.dataSecundaryColor.Status = true;
            } else {
                this.dataModal.dataSecundaryColor.Status = false;
            }
        }
    },
    methods: {
        resetDataModal() {
            this.dataModal.internalId = null;
            this.dataModal.indexId = null;
            this.dataModal.title = "";
            this.dataModal.dataName.value = null;
            this.dataModal.dataName.Status = true;
            this.dataModal.dataName.Feedback = null;
            this.dataModal.dataLogo.value = null;
            this.dataModal.dataLogo.Status = true;
            this.dataModal.dataLogo.Feedback = null;
            this.dataModal.dataPrimaryColor.value = null;
            this.dataModal.dataPrimaryColor.Status = true;
            this.dataModal.dataPrimaryColor.Feedback = null;
            this.dataModal.dataSecundaryColor.value = null;
            this.dataModal.dataSecundaryColor.Status = true;
            this.dataModal.dataSecundaryColor.Feedback = null;
            this.btnCreate = false;
            this.btnUpdate = false;
        },
        handleFileUpload(files){
            this.dataModal.dataLogo.value = files[0];
        },
        onCreate(){
            this.dataModal.title="Crear Nueva sede";
            this.dataModal.url = this.setDataStore;
            this.btnCreate = true;
            this.dataModal.btnCreate = true;
            this.$root.$emit("bv::show::modal", this.dataModal.id);
        },
        onStore(){
            this.dataModal.btnCreate = false;
            let data = new FormData();
            data.append('nombreSede', this.dataModal.dataName.value);
            data.append('logoSede', this.dataModal.dataLogo.value);
            data.append('primarioColorSede', this.dataModal.dataPrimaryColor.value);
            data.append('secundarioColorSede', this.dataModal.dataSecundaryColor.value);
            this.$root.getConexion().post(this.dataModal.url,data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                let data = response.data;
                if(data.response) {
                    data.data.records.forEach((value) => {
                        this.items.push(value);
                    });
                    this.totalRows++;
                    this.$root.$emit("bv::hide::modal", this.dataModal.id);
                    this.$swal("Buen Trabajo!", "Registro Correcto!!", "success");
                }
            }).catch(error => {
                this.errors(error.response);
            });
        },
        onEdit(item, index){
            this.dataModal.url = this.setDataUpdate;
            this.dataModal.dataId = item.internalId;
            this.dataModal.indexId = index;
            this.dataModal.title="Editar Sede";
            this.dataModal.dataName.value = item.Nombre;
            this.dataModal.dataPrimaryColor.value = item.ColorPrimario;
            this.dataModal.dataSecundaryColor.value = item.ColorSecundario;
            this.btnUpdate = true;
            this.dataModal.btnUpdate = true;
            this.$root.$emit("bv::show::modal", this.dataModal.id);
        },
        onUpdate(){            
            this.dataModal.btnUpdate = false;
            let data = new FormData();
            data.append('internalId', this.dataModal.dataId);
            data.append('nombreSede', this.dataModal.dataName.value);
            data.append('logoSede', this.dataModal.dataLogo.value);
            data.append('primarioColorSede', this.dataModal.dataPrimaryColor.value);
            data.append('secundarioColorSede', this.dataModal.dataSecundaryColor.value);
            this.$root.getConexion().post(this.dataModal.url, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                let data = response.data;
                if(data.response) {
                    this.items.splice(this.dataModal.indexId, 1);
                    data.data.records.forEach((value) => {
                        this.items.push(value);
                    });
                    this.$root.$emit("bv::hide::modal", this.dataModal.id);
                    this.$swal("Buen Trabajo!", "Actualizacion Correcto!!", "success");
                }
            }).catch(error => {
                this.errors(error.response);
            });
        },
        onDelete(internalId, index){
            this.$swal({
                title: "Eliminar Registro?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    let data = {
                        'internalId': internalId
                    }
                    this.$root.getConexion().post(this.setDataDelete,data).then(response => {
                        let data = response.data;
                        if(data.response) {
                            if (data.data.resultTransaction) {
                                this.items.splice(index, 1);
                                this.$swal("Buen Trabajo!", "Eliminacion Correcta!!", "success");
                            } else {
                                this.$swal({
                                    title: "Sin Cambios!!",
                                    icon: "error",
                                });
                            }
                        }
                    }).catch(error => {
                        this.errors(error.response);
                    });
                } else {
                    this.$swal({
                        title: "Sin Cambios!!",
                        icon: "error",
                    });
                }
            });
        },
        errors(error){
            var errors = error.data.errors;
            switch (error.status) {
                case 401:
                    window.localStorage.removeItem('accessToken');
                    this.$root.accessToken = false;
                    break;
                case 403:
                    this.$swal("ups!", "Sin Permisos!!", "error");
                    break;
                case 404:
                    this.$swal("ups!", "Error 404!!", "error");
                    break;
                case 422:
                    if(errors.nombreSede) {
                        this.dataModal.dataName.Status = false;
                        this.dataModal.dataName.Feedback = errors.nombreSede.join();                        
                    }
                    if(errors.logoSede) {
                        this.dataModal.dataLogo.Status = false;
                        this.dataModal.dataLogo.Feedback = errors.logoSede.join();
                    }
                    if(errors.primarioColorSede) {
                        this.dataModal.dataPrimaryColor.Status = false;
                        this.dataModal.dataPrimaryColor.Feedback = errors.primarioColorSede.join();
                    }
                    if(errors.secundarioColorSede) {
                        this.dataModal.dataSecundaryColor.Status = false;
                        this.dataModal.dataSecundaryColor.Feedback = errors.secundarioColorSede.join();
                    }
                    if(errors.General) {
                        this.$swal("ups!", errors.General.join(), "error");
                    }  
                    this.dataModal.btnCreate = true;  
                    this.dataModal.btnUpdate = true;                
                    break;
                case 500:
                    if(errors.General) {
                        this.$swal("ups!", errors.General.join(), "error");
                    } else {
                        this.$swal("ups!", "Error 500!!", "error");
                    }
                    break;
              default:
                  this.$swal("ups!", "Error No identificado!!", "error");
                break;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFilter() {
            this.showFilter = !this.showFilter;
        },
        onFilterNames() {
            this.showFilterNames = !this.showFilterNames;
        }
    }
};
</script>

<style>
    .tooltip-inner {
        max-width: none!important;
    }
</style>