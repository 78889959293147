<template>
  <div>
    <Factor/>
  </div>
</template>

<script>
// @ is an alias to /src
import Factor from '@/components/survey/Factor.vue'

export default {
  name: 'QuestionView',
  components: {
    Factor
  }
}
</script>
