<template>
  <b-container fluid>
    <b-row>
      <b-col lg="10"><h1 class="title">Usuarios</h1></b-col>
    </b-row>
    <b-table
      show-empty
      small
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      :tbody-transition-props="transProps"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="row.toggleDetails"
          :variant="row.detailsShowing ? 'danger' : 'info'"
        >
          <font-awesome-icon
            :icon="row.detailsShowingedit ? 'eye-slash' : 'eye'"
          />
        </b-button>
        <b-button size="sm" @click="onEdit(row.item, row.index)" variant="info">
          <font-awesome-icon icon="edit" />
        </b-button>
        <b-button
          size="sm"
          @click="onDelete(row.item.officeId, row.index)"
          variant="danger"
        >
          <font-awesome-icon icon="trash" />
        </b-button>
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-row class="justify-content-md-center">
      <b-col sm="12" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: "UserComponent",
  data() {
    return {
      getData: "/admin/users",
      items: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 25, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  mounted() {
    this.$root
      .getConexion()
      .get(this.getData)
      .then((response) => {
        let data = response.data;
        if (data.response) {
          this.items = data.data.records;
          this.fields = data.data.fields;
          this.totalRows = data.data.count;
          Object.keys(this.fields).forEach((key) => {
            if (this.fields[key].filter) {
              this.optionsFilterNames.push({
                text: this.fields[key].label,
                value: this.fields[key].key,
              });
            }
          });
        }
      })
      .catch((e) => {
        let error = e.response;
        switch (error.status) {
          case 401:
            window.localStorage.removeItem("accessToken");
            this.$root.accessToken = false;
            break;

          default:
            break;
        }
      });
  },
};
</script>

<style lang="stylus"></style>
