<template>
  <div id="app">
    <div v-if="this.$root.accessToken">
      <Menu></Menu>
      <router-view></router-view>
    </div>
    <div v-else>
      <Login />
    </div>
    <v-app>
      <v-banner v-if="deferredPrompt" color="info" dark class="text-left">
        Get our free app. It won't take up space on your phone and also works offline!
        <b-button size="sm" @click="dismiss()" variant="danger"><font-awesome-icon icon="power-off"/> Cerrar</b-button>
        <b-button size="sm" @click="install()" variant="success"><font-awesome-icon icon="power-off"/> Instalar</b-button>
      </v-banner>
      <div class="pa-4 text-center">
        <img alt="Vue logo" src="./assets/logo.png" />
        <h1>Customize Your Vue.js PWA Installation</h1>
      </div>
    </v-app>

  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'Aplication',
  components: {
    Login,
    Menu
  },
  data() {
    return {
      deferredPrompt: null,
    }
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    onResultados(component) {
      for (const key in this.components) {
        if (Object.hasOwnProperty.call(this.components, key)) {
          this.components[key] = false;
        }
      }
      // Nombre que viene de la consulta del menu.
      switch (component) {
        case 'Permisos':
          this.components.PermissionViewVisible = true;
          break;
        case 'Roles':
          this.components.RolViewVisible = true;
          break;
        case 'Usuarios':
          this.components.UserViewVisible = true;
          break;
        case 'Programas':
          this.components.ProgramViewVisible = true;
          break;
        case 'Sedes':
          this.components.CampusViewVisible = true;
          break;

        default:
          this.components.HomeViewVisible = true;
          break;
      }
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
