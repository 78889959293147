<template>
  <div>
    <Question/>
  </div>
</template>

<script>
// @ is an alias to /src
import Question from '@/components/survey/Question.vue'

export default {
  name: 'QuestionView',
  components: {
    Question
  }
}
</script>
