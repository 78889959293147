<template>
  <div>
    <div v-if="$route.name != 'Encuesta'">
      <router-view :key="$route.path"></router-view>
    </div>
    <div v-else>
      <Survey/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// console.log($route)
import Survey from '@/components/survey/Survey.vue'

export default {
  name: 'SurveyView',
  components: {
    Survey
  },
  mounted() {
    console.log(this.$route)
    // console.log(this.$useRoute())
  }
}
</script>
  