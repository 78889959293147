<template>
  <div>
    <Campus/>
  </div>
</template>

<script>
// @ is an alias to /src
import Campus from '@/components/Campus.vue'

export default {
  name: 'CampusView',
  components: {
    Campus
  }
}
</script>
