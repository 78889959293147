<template>
    <b-container fluid>
        <!-- User Interface controls -->
        <b-row>
            <b-col lg="10"><h1 class="title">Permisos</h1></b-col>
            <b-col lg="2" class="my-1 text-right">
                <b-button size="sm" @click="onFilter()" variant="primary">
                    <font-awesome-icon icon="search"/> Filtro
                </b-button>
                <b-button size="sm" @click="onCreate()" variant="danger">
                    <font-awesome-icon icon="plus"/> Adicionar
                </b-button>
            </b-col>
        </b-row>
        <div v-show="showFilter">
            <b-row>
                <b-col sm="12" md="12" lg="6">
                    <b-form-group
                        label="Filtrar"
                        label-for="filterInput"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Buscar"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Limpiar
                                </b-button>
                                <b-button @click="onFilterNames" :variant="showFilterNames ? 'danger' : 'info'">
                                    <font-awesome-icon :icon="showFilterNames ? 'eye-slash' : 'filter'"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="8" md="8" lg="4">
                    <b-form-group
                        label="Ordernar:"
                        label-for="sortBySelect"
                    >
                        <b-input-group size="sm">
                            <b-form-select
                                v-model="sortBy"
                                id="sortBySelect"
                                :options="sortOptions"
                                class="w-75"
                            >
                                <template v-slot:first>
                                    <option value="">-- ninguno --</option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc"
                                size="sm"
                                :disabled="!sortBy"
                                class="w-25"
                            >
                                <option :value="false">Asc</option>
                                <option :value="true">Desc</option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="2">
                    <b-form-group
                        label="Cantidad :"
                        label-for="perPageSelect"
                    >
                        <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="6" v-show="showFilterNames">
                    <b-form-group
                        label="Filtrar en:"
                        description="Deje todo sin marcar para filtrar todos los datos"
                    >
                        <b-form-checkbox-group 
                            v-model="filterOn"
                            :options="optionsFilterNames"
                        >
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <!-- Main table element -->
        <b-table
            show-empty
            small
            stacked="md"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :tbody-transition-props="transProps"
        >
            <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="row.toggleDetails" :variant="row.detailsShowing ? 'danger' : 'info'">
                    <font-awesome-icon :icon="row.detailsShowingedit ? 'eye-slash' : 'eye'" />
                </b-button>
                <b-button size="sm" @click="onEdit(row.item, row.index)" variant="info">
                      <font-awesome-icon icon="edit" />
                      
                </b-button>
                <b-button size="sm" @click="onDelete(row.item.officeId, row.index)" variant="danger">
                    <font-awesome-icon icon="trash" />
                </b-button>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">
                            {{ key }}: {{ value }}
                        </li>
                    </ul>
                </b-card>
            </template>
        </b-table>
        <b-row class="justify-content-md-center">
            <b-col sm="12" md="6" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal
            :id="dataModal.id"
            :title="dataModal.title"
            centered
            @hide="resetDataModal"
            :hide-footer="true"
        >
            <b-row class="justify-content-md-center">
                <b-col sm="12" md="12" lg="7">
                    <b-form-group label="Nombre :">
                        <b-form-input v-model="dataModal.dataName.value" :state="nameState"></b-form-input>
                        <b-form-invalid-feedback v-html="dataModal.dataNameFeedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="5">
                    <b-form-group label="Linea Negocio :">
                        <b-form-select v-model="dataModal.dataBusinessLine.value" :options="optionsBusinessLines" :state="businessLineState"></b-form-select>
                        <b-form-invalid-feedback v-html="dataModal.dataBusinessLineFeedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="12">
                    <b-form-group label="Dirección :">
                        <b-form-select v-model="dataModal.dataAddress.value" :options="optionsAddress" :state="addressState"></b-form-select>
                        <b-form-invalid-feedback v-html="dataModal.dataAddressFeedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="7">
                    <b-form-group label="Dirección, Información Adicional :">
                        <b-form-input v-model="dataModal.dataAddressAdd.value" :state="addressAddState"></b-form-input>
                        <b-form-invalid-feedback v-html="dataModal.dataAddressAddFeedback"></b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="text-right">
                <b-col sm="12" md="12" lg="12" class="my-3">
                    <b-button size="sm" variant="primary" v-if="btnCreate" :disabled="isDisabledCreate" @click="onStore()"><font-awesome-icon icon="fa-user"></font-awesome-icon> Adicionar</b-button>
                    <b-button size="sm" variant="primary" v-if="btnUpdate" :disabled="isDisabledUpdate" @click="onUpdate()"><i class="fa fa-check"></i> Actualizar</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'PermissionComponent',
    props: {
        getDataCreate: {
            type:String,
            default:'get-data-create'
        },
        setDataStore: {
            type:String,
            default:'set-data-store'
        },
        setDataUpdate: {
            type:String,
            default:'set-data-update'
        },
        setDataDelete: {
            type:String,
            default:'set-data-delete'
        }
    },
    data() {
        return {
            getData: '/admin/permission',
            showFilter: false,
            showFilterNames : false,
            optionsFilterNames: [],
            optionsAddress: [],
            optionsBusinessLines: [],
            transProps: {
                // Transition name
                name: "flip-list"
            },
            items: [],
            fields: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [15, 25, 50, 100],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            dataModal: {
                id: "dataSucursalModal_",
                title: "",
                indexId:null,
                dataId:null,
                dataName: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataBusinessLine: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataAddress: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                dataAddressAdd: {
                    value: null,
                    Status: true,
                    Feedback: null
                },
                url: null,
                btnCreate : false,
                btnUpdate : false
            },
            btnCreate : false,
            btnUpdate : false
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
        isDisabledCreate() {
            return !this.dataModal.btnCreate;
        },
        isDisabledUpdate() {
            return !this.dataModal.btnUpdate;
        },
        nameState() {
            return this.dataModal.dataName.Status;
        },
        businessLineState() {
            return this.dataModal.dataBusinessLine.Status;
        },
        addressState() {
            return this.dataModal.dataAddress.Status;
        },
        addressAddState() {
           return this.dataModal.dataAddressAdd.Status; 
        }
    },
    mounted() {
        this.$root.getConexion().get(this.getData).then(response => {
              let data = response.data;
              if(data.response) {
                this.items = data.data.records;
                this.fields = data.data.fields;
                this.totalRows = data.data.count;
                Object.keys(this.fields).forEach(key => {
                    if (this.fields[key].filter) {
                        this.optionsFilterNames.push({ text: this.fields[key].label, value: this.fields[key].key });
                    }
                })
              }
          }).catch(e => {
            let error =  e.response;
            switch (error.status) {
              case 401:
                window.localStorage.removeItem('accessToken');
                this.$root.accessToken = false;
                break;
            
              default:
                break;
            }
          });
    },
    methods: {
        resetDataModal() {
            this.dataModal.OfficeId = null;
            this.dataModal.indexId = null;
            this.dataModal.title = "";
            this.dataModal.dataName.value = null;
            this.dataModal.dataName.Status = true;
            this.dataModal.dataBusinessLine.value = null;
            this.dataModal.dataBusinessLine.Status = true;
            this.dataModal.dataAddress.value = null;
            this.dataModal.dataAddress.Status = true;
            this.dataModal.dataAddressAdd.value = null;
            this.dataModal.dataAddressAdd.Status = true;
            this.btnCreate = false;
            this.btnUpdate = false;
        },
        onCreate(){
            this.dataModal.title="Crear Nueva Sucursal";
            this.dataModal.url = this.setDataStore;
            this.$root.getConexion().get(this.getDataCreate).then(response => {
                this.optionsAddress = response.data.address;
                this.optionsBusinessLines = response.data.businessLines;
            });
            this.btnCreate = true;
            this.dataModal.btnCreate = true;
            this.$root.$emit("bv::show::modal", this.dataModal.id);
        },
        onStore(){
            this.dataModal.btnCreate = false;
            let data = {
                'name': this.dataModal.dataName.value,
                'businessLine': this.dataModal.dataBusinessLine.value,
                'address': this.dataModal.dataAddress.value,
                'addressAdd': this.dataModal.dataAddressAdd.value
            }
            this.$root.getConexion().post(this.dataModal.url,data).then(response => {
                if(response.status === 200) {
                    this.items.push(response.data);
                    this.totalRows++;
                    this.$root.$emit("bv::hide::modal", this.dataModal.id);
                    this.$swal("Buen Trabajo!", "Registro Correcto!!", "success");
                }
            }).catch(error => {
                this.errors(error);
            });
        },
        onEdit(item, index){
            this.dataModal.url = this.setDataUpdate;
            this.dataModal.dataId = item.officeId;
            this.dataModal.indexId = index;
            this.$root.getConexion().get(this.getDataCreate).then(response => {
                this.optionsAddress = response.data.address;
                this.optionsBusinessLines = response.data.businessLines;
            });
            this.dataModal.title="Editar Sucursal";
            this.dataModal.dataName.value = item.officeName;
            this.dataModal.dataBusinessLine.value = item.officeBusinessLineId;
            this.dataModal.dataAddress.value = item.officeAddressId;
            this.dataModal.dataAddressAdd.value = item.officeAddressAdd;
            this.btnUpdate = true;
            this.dataModal.btnUpdate = true;
            this.$root.$emit("bv::show::modal", this.dataModal.id);
        },
        onUpdate(){            
            this.dataModal.btnUpdate = false;
            let data = {
                'id': this.dataModal.dataId,
                'name': this.dataModal.dataName.value,
                'businessLine': this.dataModal.dataBusinessLine.value,
                'address': this.dataModal.dataAddress.value,
                'addressAdd': this.dataModal.dataAddressAdd.value
            }
            this.$root.getConexion().post(this.dataModal.url,data).then(response => {
                if(response.status === 200) {
                    this.items.splice(this.dataModal.indexId, 1);
                    this.items.push(response.data);
                    this.$root.$emit("bv::hide::modal", this.dataModal.id);
                    this.$swal("Buen Trabajo!", "Actualizacion Correcto!!", "success");
                }
            }).catch(error => {
                this.errors(error);
            });
        },
        onDelete(item, index){
            this.$swal({
                title: "Eliminar Registro?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    let data = {
                        'id': item
                    }
                    this.$root.getConexion().post(this.setDataDelete,data).then(response => {
                        if(response.status === 200) {
                            if (response.data) {
                                this.items.splice(index, 1);
                                this.$swal("Buen Trabajo!", "Eliminacion Correcta!!", "success");
                            }
                        }
                    }).catch(error => {
                        this.errors(error);
                    });
                } else {
                    this.$swal({
                        title: "Sin Cambios!!",
                        icon: "error",
                    });
                }
            });
        },
        errors(error){
            if(error.response.status === 403) {
                this.$swal("ups!", "Sin Permisos!!", "error");
            }
            
            if(error.response.status === 500) {
                this.$swal("ups!", "Error 500!!", "error");
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFilter() {
            this.showFilter = !this.showFilter;
        },
        onFilterNames() {
            this.showFilterNames = !this.showFilterNames;
        }
    }
};
</script>

<style lang="stylus">

</style>