import Home from '@/views/Home.vue'
import Permission from '@/views/config/Permission.vue'
import User from '@/views/config/User.vue'
import Campus from '@/views/Campus.vue'

import Factor from '@/views/survey/Factor.vue'
import Survey from '@/views/survey/Survey.vue'
import Question from '@/views/survey/Question.vue'

const routes = [
    { path: '/home', name: 'Inicio', icon: 'home', component: User, permission: "home" },
    {
        path: '/intervetion', name: 'Intervenciones', icon: 'lightbulb', component: Home, children: [
            { path: '/individual', name: 'Individual', icon: 'user-alt', component: Campus },
            { path: '/group', name: 'Grupal', icon: 'users', component: Permission }
        ]
    },
    {
        path: '/survey', name: 'Encuestas', icon: 'check-double', component: Survey, children: [
            { permission:"read_factor", path: '/section', name: 'Secciones', icon: 'bars', component: Factor },
            { path: '/questions', name: 'Preguntas', icon: 'question', component: Question },
            { path: '/answers', name: 'Respuestas', icon: 'marker', component: Home }
        ]
    },
    {
        path: '/config', name: 'Configuracion', icon: 'cogs', component: Permission, children: [
            { path: '/permission', name: 'Permisos', icon: 'sitemap', component: Permission },
            { path: '/user', name: 'user', icon: 'users-cog', component: Home }
        ]
    },
    // { path: "*", component: PageNotFound }
    // { path: "*", name: 'user', component: Home }
]

export default routes
