<template>
  <div>
    <Permission/>
  </div>
</template>

<script>
// @ is an alias to /src
import Permission from '@/components/Permission.vue'

export default {
  name: 'PermissionView',
  components: {
    Permission
  }
}
</script>
