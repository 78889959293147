import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUsersCog, faTools, faBars,faSitemap, faCogs, faEyeSlash,faEye,faEdit,faTrash, faFilter, faSearch, faPlus, faPowerOff, faCheck, faUser, faCheckDouble, faQuestion, faMarker, faLightbulb, faUsers, faUserAlt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from "./App.vue"
import VueSwal from 'vue-swal'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'


Vue.config.productionTip = false
Vue.prototype.$appName = 'My App'
Vue.prototype.$token = null

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSwal)

library.add(faHome)
library.add(faUsersCog)
library.add(faTools)
library.add(faCogs)
library.add(faSitemap)
library.add(faBars)
library.add(faEyeSlash)
library.add(faFilter)
library.add(faEye)
library.add(faEdit)
library.add(faTrash)
library.add(faSearch)
library.add(faPlus)
library.add(faPowerOff)
library.add(faCheck)
library.add(faUser)
library.add(faCheckDouble)
library.add(faQuestion)
library.add(faMarker)
library.add(faLightbulb)
library.add(faUsers)
library.add(faUserAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
const router = new VueRouter({
  routes
})
new Vue({
  data() {
    return {
      accessToken: false
    }
  },
  methods: {
    getConexion() {
      const base = axios.create();
      base.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('accessToken')}`;
      // base.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      // base.defaults.headers.common['Content-type'] = 'application/json';
      // base.defaults.headers.common['Content-type'] = 'multipart/form-data';
      // base.defaults.headers.common['Content-type'] = 'application/x-www-form-urlencoded';
      // base.defaults.baseURL = "http://localhost:8000/";
      base.defaults.baseURL = "https://ebox.logiasoft.com/";
      return base;
    }
  },
  watch: {
    accessToken: function(newStatus) {
      if (!newStatus) {
        window.localStorage.removeItem('accessToken');
      }
    }
  },
  mounted() {
    if (window.localStorage.getItem('accessToken') !== undefined && window.localStorage.getItem('accessToken')) {
      this.accessToken = true;
    } else {
      this.accessToken = false;
    }
  },
  render: h =>h(App),
  router
}).$mount('#app')
