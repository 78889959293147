<template>
  <div>
    <div class="menu">
      <b-navbar type="dark" variant="info" fixed="top" role="navigation">
        <b-navbar-brand to="/">
          <img src="../assets/logo.png" alt="Kitten" width="30">
          Artemis
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button v-b-toggle.menu><font-awesome-icon icon="bars" /> Menu</b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-sidebar id="menu" title="Artemis" right shadow sidebar-class="border-right border-danger">
        <template>
          <div class="px-3 py-2">
            <nav class="mb-3">
              <b-nav vertical justified v-for="menu in $router.options.routes" v-bind:key="menu.id">
                <b-nav-item :to="menu.path" v-if="!menu.children" v-show="validatePermission(allPermissions, menu.permission, menu.children)" class="text-left" exact exact-active-class="active"><font-awesome-icon :icon="menu.icon"/> {{ menu.name }}</b-nav-item>
                <b-nav-item :to="menu.path" v-else v-show="validatePermission(allPermissions, menu.permission, menu.children)" class="text-left" exact exact-active-class="active">
                  <b-row align-v="center">
                    <b-col sm="9"><font-awesome-icon :icon="menu.icon"/> {{ menu.name }}</b-col>
                    <b-col sm="3" class="text-right"><b-button :href="'#'+menu.name" v-b-toggle size="sm"><font-awesome-icon icon="plus"/></b-button></b-col>
                  </b-row>
                </b-nav-item>
                  
                <b-collapse
                  :id="menu.name"
                  :text="menu.name"
                  toggle-class="nav-link-custom"
                  justify
                  class="subMenu"
                >
                  <div
                    v-for="child in menu.children" 
                    v-bind:key="child.id">
                    <b-nav-item :to="child.path" v-show="validatePermission(allPermissions, child.permission, child.children)" class="subMenuItem" exact exact-active-class="active"><font-awesome-icon :icon="child.icon"/> {{ child.name }}</b-nav-item>
                  </div>
                </b-collapse>

              </b-nav>
            </nav>
          </div>
        </template>
        <template #footer>
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <strong class="mr-auto">Sesion Activa</strong>
          <b-button size="sm" @click="cerrarSession()" variant="danger"><font-awesome-icon icon="power-off"/> Cerrar</b-button>
        </div>
        </template>
      </b-sidebar>
    </div>    
  </div>
</template>
<script>
  export default {
    data() {
        return {
          route:{
            menuUser:'/person/permission',
            closeSession:'/person/logout',
          },
          allPermissions : [],
        }
    },
    methods: {
        cerrarSession() {
            window.localStorage.removeItem('accessToken');
            this.$root.token = null;
            this.$root.accessToken = false;
            // this.$root.getConexion().get(this.route.closeSession).then(response => {
            //     let data = response.data;
            //     if(data.response) {
            //         window.localStorage.removeItem('accessToken');
            //         this.$root.token = null;
            //         this.$root.accessToken = false;
            //     }
            // }).catch(e => {
            //     let error =  e.response;
            //     switch (error.status) {
            //     case 401:
            //         window.localStorage.removeItem('accessToken');
            //         this.$root.accessToken = false;
            //         break;
                
            //     default:
            //         break;
            //     }
            // });
        },
        validatePermission(allPermission, permission, childrens) { 
            let response = false
            if (childrens) {
                childrens.forEach(children => {
                    console.log("c_permission " +children.permission)
                    let tmp = this.validatePermission(allPermission, children.permission, false)
                    if (tmp) {
                        response = tmp
                    }
                })
            } else {
                allPermission.forEach(element => {
                    console.log(element + "  " + permission + "  " + (element == permission))
                    if (element == permission) {
                        response = true
                    }
                });
            }
            return response
        }
    },
    mounted() {
        this.$root.getConexion().get(this.route.menuUser).then(response => {
            response = response.data
            if(response.success) {
                response.data.forEach(element => {
                    this.allPermissions.push(element.permission)
                })
            } else {
              this.allPermissions = [];
            }
        }).catch(error => {
            console.log(error)
        });
    }
  }
</script>

<style>
  .menu {
    padding: 50px;
  }
  /* nav a {
    color: black !important;
    background-color:aqua !important;
  }
  nav a:hover{
    color: black !important;
    background-color:beige !important;
  } */
  .subMenu {
    padding-left: 20px!important;
  }
</style>
