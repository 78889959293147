<template>
  <div class="wrapper vcenter-item">
    <b-container>
      <b-row>
        <b-col sm="12" md="6" offset-md="3" class="p-4 mt-4 rounded-4 border border-success">
          <b-form-group
            id="fieldset-usuario"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="12"
            description="Indique su usuario para el sistema."
            label="Usuario : "
            label-for="input-usuario"
          >
            <b-form-input id="input-usuario" @keyup.enter="getAccessToken" v-model="loginForm.user"></b-form-input>
          </b-form-group>
            <b-form-group
              id="fieldset-password"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="12"
              description="Indique su contraseña para el sistema."
              label="Contraseña : "
              label-for="input-password"
            >
              <b-form-input type="password" id="input-password" @keyup.enter="getAccessToken" v-model="loginForm.pass"></b-form-input>
            </b-form-group>
            <b-form-group class="text-center mt-3">
              <b-button variant="success" @click="getAccessToken">Ingresar</b-button>
            </b-form-group>
            {{ this.$root.accessToken }}
        </b-col>
      </b-row>
    </b-container>
    
  </div>
</template>

<script>
const qs = require('qs');
export default {
  data() {
      return {
          urlLogin:'token',
          loginForm:{
            user: 'johndoe',
            pass: ''
          },
          data: [],
      };
  },
  methods: {
      getAccessToken() {
        this.$root.getConexion().post(this.urlLogin, qs.stringify({
            username:this.loginForm.user,
            password:this.loginForm.pass,
            remember_me:0
          })).then(response => {
            if(response.status == 200) {
              window.localStorage.setItem('accessToken', response.data.access_token)
              this.$root.accessToken = true
            }
          })
          .catch(e => {
            console.log(e)
          });
      }
      
  }
}
</script>

<style>
  .vcenter-item{
      display: flex;
      align-items: center;
  }
</style>